.HistoryCard {
    display: flex;
    flex-direction: column;
    background-color: #ededed;
    padding: 1em;
    border-radius: 3px;
    margin-bottom: 1em;
    text-align: left;
}

.HistoryLine {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 30em;
}

.HistoryLineBold {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 30em;
    font-weight: bold;
}

.Title {
    margin-bottom: 1em;
    text-align: left;
}