@import "../../../styles//variables.scss";
$important: unquote("!important");

.LocationFormRoot {
    display: flex;
    padding: 1em;
    flex-direction: column;
}

.LocationHeaderPopup {
    display: flex;
    flex-direction: column;
}

.MobileLocationHeader {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    align-items: center;
}

.LocationHeader {
    display: flex;
    flex-direction: column;
    padding-top: 4em;
    align-items: center;
}

.LocationFormTitle {
    font-weight: bold;
    color: $primaryColor;
}

.UnknownUserLocationFormTitle {
    font-weight: bold;
}

.MobileLocationFormLabel {
    padding: 0.5em;
    text-align: left;
}

.LocationFormLabel {
    // margin: 1em;
    padding-top: 1em;
    padding-left: 0.5em;
    text-align: left;
    width: 100%;
}

.UnKnownUserLocationFormLabel {
    padding-top: 1em;
    padding-left: 0.5em;
    width: 100%;
}

.LocationFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.75em !important;
}

.MobileFormContent {
    max-width: 17em;
    margin-top: 0.75em !important;
}

.FormContent {
    max-width: 20em;
    margin-top: 0.75em !important;
}

.LocationMsgContainer {
    display: flex;
    align-items: center;
    margin: 1em 0 1em 0;
    max-width: 30em;
    justify-content: center;
}

.LocationMsg {
    text-align: start;
    margin-left: 0.5em;
}

.FormActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 25em;
    padding-top: 1em;
}

.MobileFormActions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}






