@import "../../../styles/variables.scss";
$important: unquote("!important");

.container {
  display: flex;
  padding: 1em;
  flex-direction: column;
}

.title {
  padding-top: 0em;
}

.subtitle {
  margin-left: 20px;
  margin-right: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.button {
  margin-left: 10px;
  margin-right: 10px;
}

.mandatoryInfo {
  font-style: italic;
  margin-left: 20px;
}

.mandatoryInfoSymbol {
  color: red;
}

.tvCodeMsgContainer {
  display: flex;
  align-items: center;
  margin: 1em 0 1em 0;
  max-width: 30em;
  justify-content: center;
}

.checkRoomTitle {
  color: $primaryColor;
}

.roomSelector {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}