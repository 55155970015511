.CGUItem {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin: 1em;
  align-items: center;
}

.IFrame {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.Modal {
  width: 100%;
  height: 100%;
}

.Title {
  text-align: left;
}