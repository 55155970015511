.Form {
  display: flex;
  flex-direction: column;
  padding: 1em;
  align-self: center;
  width: 100%;
}

.Actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 2em;
  align-items: center;
  width: 100%;
  align-self: center;
}

.Fixed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
  width: 100%;
  align-self: center;
  bottom: 0;
  background-color: white;
  position: fixed;
}

.Content {
  width: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-self: center;
}

.SelectedSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.Title {
  margin: 0 !important;

  padding: 0 0.75rem 0 0.75rem !important;
}

.RefDate {
  color: #2996f3;
  font-weight: bold;
}

.Subtitle {

  font-size: smaller;
}


.Empty {
  align-self: center;
    padding: 2em;
    font-weight: 700;
}

.ErrorMsg {
  color: red;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.WarningMsg {
  color: #e46c31;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.PopupTitle {
  font-weight:bold;
}


.SimpleCard {
    min-width: 20em;
    padding: 1.5em;
    background-color: white;
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content: space-between;
}

.TextCard {

  padding: 2em;
  display: flex;
  flex-direction: column;
 
}

.Text {
  margin-bottom: 1em;
}

 .ModalActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
} 

.MobileModalActions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}