@import "../../../styles/variables.scss";
$important: unquote("!important");

.ErrorMsg {
    font-size: smaller;
    color: red;
    width: 100%;
}

.InfoMsg {
    margin-top: 1em;
}

.MobileFormData {
    height: 100%;
    width: 100%;
}

.MobilePersonalDataForm {
    display: flex;
    align-items: center;
    padding: 1em;
    flex-direction: column;
}

.Notice {
    align-self: flex-start;
    margin-top: 1em;
}

.MobileContent {
    flex-wrap: wrap;
    flex-grow: 1;
    margin-bottom: 2em;
}

.MobileValidateButton {
    background: $primaryColor;
    color: white;
    font-weight: bold;
    min-height: 40px;
}

.MobileDisabledButton {
    background: #ccc;
    color: #999;
    cursor: default;
    border: none;
    outline: 0;
    box-shadow: none;
}

.MobilePageContent {
    padding: 0;
}

.MobileHeader{
    padding : 1em;
    margin-top: 20px;
}

.BirthdatePicker {
    input {
        background-color: #ededed;
    }
}

.MobileBirthdatePicker {
    width: 100%;
    input {
        background-color: #ededed;
        max-width: none !important;
        padding-left: 1em;
        margin-bottom: 0.5em
    }
}

.PhoneNumber {
    overflow: hidden;
    padding-bottom: 0.25em;
    border-bottom: 1px solid black;
}

.Actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
}

.ModifyButton{
    color: white;
    font-weight: bold;
    text-decoration: none;
    width: 8em;
    min-height: 2em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 5px;
    margin-right: 1em;
    border-radius: 4px;
    height: 3em;
    background-color: $primaryColor $important;
}

.Focused {
    color: #3f51b5 !important;
}


.FormContent {
    max-width: 20em;
    margin-top: 0.75em !important;
}


.PhoneInput {
    min-width: -webkit-fill-available;
    border-radius: 4px;   
     background-color: #b9b9b947;
    margin-bottom: 0.4em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1em;
    padding-left: 1em;
    width: 13.5em;
    margin-left: 4em;
}

.PhoneTheme {
    font-size: 16px;
    background: #b9b9b947;
    border: 1px solid #b9b9b947;
    border-radius: 4px;
    width: 13.5em;
    margin-left: 4em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1em;
    padding-left: 1em;
    outline: none;
    transition: box-shadow ease .25s,border-color ease .25s;
}


.PhoneLabel{
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: smaller;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-left: 0.5em;
}

.internError {
    position: absolute;
    right: 5px;
    top: 5px;
}
