
.react-tel-input {
  border-bottom: 1px solid rgba(148, 148, 148, 1);
  &:hover {
    border-bottom: 2px solid #000;
  }


  &.focused {
    border-bottom: 2px solid #3f51b5 !important;
  }

  & + div:before {
    position: absolute;
    top: -7px;
    left: 19px;
    display: block;
    background: white;
    padding: 0 5px;
    font-size: 13px;
    width: 70px;
  }
  .form-control {
    max-width: none !important;
    color: currentColor;
    font-size: 16px;
    background: #b9b9b947;
    border: 1px solid #b9b9b947;
    border-radius: 4px;
    width: 13.5em;
    margin-left: 4em;
    margin-bottom: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1em;
    padding-left: 1em;
    outline: none;
    transition: box-shadow ease 0.25s, border-color ease 0.25s;
    &:hover {
      border: none;
    }
    &:focus {
      border: none;
      box-shadow: none;
      background: #b9b9b947;

    }
  }
  .flag-dropdown {
    outline: none;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    border-radius: 3px 0 0 3px;
    &.open {
      z-index: 2;
    }
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
  input[disabled] {
    & + .flag-dropdown {
      &:hover {
        cursor: default;
        .selected-flag {
          background-color: transparent;
        }
      }
    }
  }
  .selected-flag {
    position: relative;
    width: 52px;
    height: 100%;
    padding: 0 0 0 11px;
    border-radius: 3px 0 0 3px;
    .flag {
      position: absolute;
      top: 50%;
      margin-top: -12px;
    }
    .arrow {
      position: relative;
      top: 50%;
      margin-top: -1px;
      left: 29px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;
      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }
  .country-list {
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
    background-color: white;
    width: 300px;
    max-height: 220px;
    overflow-y: scroll;
    border-radius: 7px;
    .flag {
      display: inline-block;
      position: absolute;
      left: 13px;
      top: 8px;
    }
    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }
    .country {
      position: relative;
      padding: 12px 9px 13px 46px;
      .dial-code {
        color: #6b6b6b;
      }
      &:hover {
        background-color: #f1f1f1;
      }
      &.highlight {
        background-color: #f1f1f1;
      }
    }
    .flag {
      margin-right: 7px;
      margin-top: 2px;
    }
    .country-name {
      margin-right: 6px;
    }
    .search {
      z-index: 2;
      position: sticky;
      top: 0;
      background-color: #fff;
      padding: 10px 0 6px 10px;
    }
    .search-emoji {
      display: none;
      font-size: 15px;
    }
    .search-box {
      border: 1px solid #cacaca;
      border-radius: 3px;
      font-size: 15px;
      line-height: 15px;
      margin-left: 6px;
      padding: 3px 8px 5px;
      outline: none;
      &:hover {
        border-color: #505050;
      }
    }
    .no-entries-message {
      padding: 7px 10px 11px;
      opacity: 0.7;
    }
  }
}
