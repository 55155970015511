@import "./variables";

@media screen and (min-width: $breakpoint-small) {
  html {
    background-color: $background-color;
  }
  .App {
    background-color: $background-color;
  }
  .page {
    display: grid;
    align-items: flex-start;
    margin-top: 4em;
    margin-right: 1.25em;
    margin-left: 1.25em;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 5em;
    min-height: 88%;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .flag-text {
    margin-left: 0.25em;
  }
  a {
    text-decoration: none !important;
    color: #0f4877;
  }
  .no-left {
    margin-left: 0 !important;
  }
  .drawer {
    width: 240px;
    z-index: 1000 !important;
    flex-shrink: 0;

    .shadowed {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }

    .sidebar {
      height: 100%;
    }

    .menuHeader {
      margin-top: 5.5em;
      .logo {
        max-width: 80%;
        border: 1px solid #eeeeee;
        border-radius: 10px;
      }
      .bordered {
        border-bottom: 0.25px solid #ded8d5;
        padding-bottom: 15px;
        margin-right: 0.75em;
        margin-left: 0.75em;
      }
    }
  }
  .smallScreenBack {
    display: none;
  }
  .menuButton {
    display: none !important;
  }
  .drawerPaper {
    z-index: 997;
  }
  .pageActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .backButton,
    .actionButton {
      color: white;
      font-weight: bold;
      text-decoration: none;
      padding-left: 0.5em;
      padding-right: 0.5em;
      min-width: 8em;
      min-height: 2em;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 5px;
      margin-left: 1em;
      border-radius: 4px;
      height: 3em;
      background-color: $primaryColor;
      cursor: pointer;
    }
  }

  button {
    &.button-small {
      margin-top: 0.25em !important;
    }
  }

  .withMenu {
    text-align: initial;
    margin-left: 255px !important;
  }

  .pluginPage {
    display: flex;
    flex-direction: column;
  }
  .headerTitle {
    align-self: flex-start;
    margin-left: 1em;
  }

  .width30 {
    width: 30em;
  }
}

@media screen and (max-width: $breakpoint-small +1) {
  html {
    background-color: white;
  }
  .App {
    background-color: white;
  }
  .page {
    margin-top: 2em;
    margin-bottom: 5em;
    h1 {
      padding: 0.75em;
    }
  }

  .container {
    margin: 2.25em 0.25em 5em 0.25em;
  }

  .withTop {
    margin-top: 1em;
  }

  .withTop2 {
    margin-top: 2em;
  }

  .withBottom {
    margin-bottom: 1em !important;
  }

  .noTop {
    margin-top: 0 !important;
  }

  .noLeft {
    margin-left: 0 !important;
  }

  .planTableRow {
    > th,
    td {
      max-width: 8em;
      padding: 5px;
    }
  }

  .planTableRowup {
    > th,
    td {
      max-width: 10em;
      padding: 5px;
    }
  }

  .smallScreenBack {
    padding-left: 10px;
    padding-top: 10px;
    align-self: start;
    color: $primaryColor !important;
  }
  .menu,
  .menuHeader {
    text-align: center;
  }
  .userMenu {
    justify-content: center !important;
  }
  .flag-text {
    display: none !important;
  }
  input {
    max-width: 10em !important;
  }
  .select {
    max-width: 16em !important;
  }
  .langChooser {
    div {
      padding-right: 8px !important;
      padding-left: 8px !important;
    }
  }
  .menuHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      max-width: 80%;
      border: 1px solid #eeeeee;
      border-radius: 10px;
    }
    .bordered {
      width: 100%;
      border-bottom: 0.25px solid #ded8d5;
      padding-bottom: 15px;
      margin-right: 0.75em;
      margin-left: 0.75em;
    }
  }

  button {
    &.button-small {
      margin-top: 0.25em !important;
      margin-right: 0.25em;
      padding: 10px 10px 10px 10px !important;
      border-radius: 50px !important;
      min-width: min-content !important;
      border: 1px solid $primaryColor;
      .label {
        display: none !important;
      }
    }
  }

  .pageContent {
    padding: 1em;
  }

  .pageActions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    > nav {
      padding-left: 0.25em;
      background-color: #c8e0f3;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      a {
        color: rgb(6, 75, 116);
        text-decoration: none !important;
      }
      > ol {
        flex-wrap: nowrap;
        font-size: 0.85rem;
      }
    }
    .actionButton {
      margin-top: 1em;
    }
    .backButton,
    .actionButton {
      color: white;
      font-weight: bold;
      text-decoration: none;
      padding: 10px 10px 10px 10px;
      display: flex;
      align-items: center;
      border-radius: 50px;
      background-color: $primaryColor;
      width: min-content;
      margin-left: 1em;
      .label {
        display: none !important;
      }
    }
  }
}

.root {
  display: "flex";
}

.bold {
  font-weight: bold;
}

.warning {
  color: gray;
}

.green {
  color: green;
}

.mediumSized {
  width: 15em;
}

.left {
  text-align: start;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.border {
  border: 1px solid #ded8d5;
  padding: 1em;
  border-radius: 5px;
}

.max-content-width {
  width: max-content;
}

.checkbox-info {
  span {
    font-size: small;
  }
}

.flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
  &.start {
    align-items: flex-start;
  }
  &.spaceBetween {
    justify-content: space-between;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.baseline {
    align-items: baseline;
  }
  &.center {
    justify-content: center;
  }
  &.alignCenter {
    align-items: center;
  }
  &.end {
    justify-content: flex-end;
  }
}
.self-center {
  align-self: center;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-start;
}

.card {
  margin: 0.25em;
}

.marginTop {
  margin-top: 1em !important;
}

.paymentForm {
  display: flex;
  flex-direction: column;
  .paymentInput {
    margin: 0.5em;
    flex: 1;

    input {
      border-radius: 4px;

      border: none;
      margin-top: 0.75em;
      margin-bottom: 0.5em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-right: 1em;
      padding-left: 1em;
    }
  }
}

.cardItem {
  background-color: #ededed;
  padding: 1em;
  border-radius: 3px;
  margin-bottom: 1em;
}

.tooltipIcon {
  height: 20px !important;
}

.appBar {
  z-index: 1300 !important;
  box-shadow: 0 0 0 0 !important;
}
.menuButton {
  margin-right: 1em;
}
.toolbar {
  background-color: $primaryColor;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.drawerPaper {
  width: 240px;

  z-index: 1301;
}
.content {
  flex-grow: 1;
  padding: 1em;
}

.closeMenuButton {
  width: 2em;
  margin-left: 0.25em;
  margin-top: 0.25em;
}

.flag-icon {
  margin-right: 0.25em;
}

.link {
  font-weight: bold;
  color: $secondaryColor;
  &:hover {
    cursor: pointer;
  }
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.miniMarginBottom {
  margin-bottom: 0.25em;
}

.menu {
  .menuItem {
    margin-left: 0.5em;
    margin-right: 0.5em;
    display: flex;
    border: none;
    align-items: center;
    height: 3.5em;
    border-radius: 3px;
    text-decoration: none;
    margin-top: 0.25em;
    color: $secondaryColor;
    justify-content: space-around;
    padding-left: 1em;
    &.selected {
      background-color: $thirtyColor;
    }
    .itemText {
      font-size: 0.875rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      text-transform: uppercase;
      flex: 1.5;
    }
  }
}

.linkMenuSelected {
  .material-icons {
    color: $secondaryColor !important;
  }
}

.linkMenuItem {
  text-decoration: none;
  &.selected {
    background-color: #e0e0e0;
  }
}
.titleActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  .quitAction {
    cursor: pointer;
  }
}

.buttonColor {
  &.blue {
    background: $primaryColor !important;
    color: white !important;
  }
  &.white {
    background: white !important;
    color: $primaryColor !important;
    border: 1px solid $primaryColor;
  }
  &.margin {
    margin: 0.25em !important;
  }
}

.buttonColor:disabled {
  background: #ccc !important;
  color: #999 !important;
  cursor: default;
  border: none;
  outline: 0;
  box-shadow: none;
}

.error {
  color: $error-color;
}

.main {
  min-height: 50em;
  text-align: center;
}

.hospitalList {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  .card {
    margin: 1em;
    min-width: 18em;
    .media {
      height: 6em;
    }
  }
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pageContent {
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .logo {
      max-width: 30em;
      min-width: 20em;
    }
    margin-top: 0.75em;
    width: 100%;
    .loginForm,
    .personnalDataForm {
      button {
        min-width: 80px;
        border: none;
        min-height: 40px;
        border-radius: 3px;
        font-weight: bold;
        color: white;
        margin-top: 1em;
      }

      .blue {
        background: $primaryColor;
      }

      .blue:disabled {
        background: #ccc;
        color: #999;
        cursor: default;
        border: none;
        outline: 0;
        box-shadow: none;
      }

      ::placeholder {
        color: #767676;
      }

      #forgotten {
        color: #5580db;
        width: 25em;
        cursor: pointer;
      }

      .select {
        margin-top: 1em;
        min-width: -webkit-fill-available;
      }

      input {
        border-radius: 4px;
        border: none;

        margin-bottom: 0.5em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1em;
        padding-left: 1em;

        min-width: -webkit-fill-available;
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
      }
      .formInput {
        width: 100%;
        label {
          z-index: 99;
          margin-left: 0.5em;
          > span {
            color: red;
          }
        }
        > div {
          width: 100%;
        }
      }
    }
  }

  .personnalDataForm {
    .formInput {
      label {
        z-index: 99;
        margin-left: 0.5em;
        > span {
          color: red;
        }
      }
    }
    .select {
      margin-top: 16px;
      label {
        font-size: 1rem;
        z-index: 99;
        margin-left: 0.5em;
      }

      #civility {
        height: 1.1875em;
        background-color: #ededed !important;
        border: none;
        margin-bottom: 0.5em;

        padding-bottom: 0.5em;
        padding-right: 1em;
        padding-left: 1em;
        border-radius: 4px;
      }
    }
    .dateInput {
      margin-top: 0px;
      input {
        background-color: #ededed !important;
      }
    }
    .root {
      .content {
        display: flex;
        flex-wrap: wrap;
        .form-data {
          height: 100%;
          padding: 1em;
          width: 100%;
          @media screen and (min-width: $breakpoint-small) {
            flex: 1;
          }
        }
      }
    }
  }
}

.userPage {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  .pageContent {
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .loginForm,
    .personnalDataForm {
      .blue {
        background: $primaryColor;
        color: white;
        font-weight: bold;
        min-height: 40px;
      }

      .blue:disabled {
        background: #ccc;
        color: #999;
        cursor: default;
        border: none;
        outline: 0;
        box-shadow: none;
      }

      ::placeholder {
        color: #767676;
      }

      #forgotten {
        color: #5580db;
        width: 25em;
        cursor: pointer;
      }

      .select {
        margin-top: 1em;
        min-width: -webkit-fill-available;
      }

      input {
        border-radius: 4px;
      //  background-color: #ededed;
        border: none;
        margin-bottom: 0.5em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1em;
        padding-left: 1em;

        min-width: -webkit-fill-available;
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
      }
      .formInput {
        width: 100%;
        label {
          z-index: 99;
          margin-left: 0.5em;
          > span {
            color: red;
          }
        }
        > div {
          width: 100%;
        }
      }
    }
  }

  .personnalDataForm {
    .formInput {
      label {
        z-index: 99;
        margin-left: 0.5em;
        > span {
          color: red;
        }
      }
    }
    .select {
      margin-top: 16px;
      label {
        font-size: 1rem;
        z-index: 99;
        margin-left: 0.5em;
      }

      #civility {
        height: 1.1875em;
        background-color: #ededed !important;
        border: none;
        margin-bottom: 0.5em;

        padding-bottom: 0.5em;
        padding-right: 1em;
        padding-left: 1em;
        border-radius: 4px;
      }
    }
    .dateInput {

        margin-top: 0px;
        overflow: hidden;
        input {
        background-color: #ededed !important;
      }
        width: 100%;
     

    }
    .root {
      .content {
        display: flex;
        flex-wrap: wrap;
        .form-data {
          height: 100%;
          padding: 1em;
          width: 100%;
          @media screen and (min-width: $breakpoint-small) {
            flex: 1;
          }
        }
      }
    }
  }
}

.personalForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: start !important;

  .pageContent {
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    margin-top: 0.75em;
    .personnalDataForm {
      button {
        min-width: 80px;
        border: none;
        min-height: 40px;
        border-radius: 3px;
        font-weight: bold;
        color: white;
        margin-top: 1em;
        align-self: flex-end;
      }

      .blue {
        background: $primaryColor;
      }

      .blue:disabled {
        background: #ccc;
        color: #999;
        cursor: default;
        border: none;
        outline: 0;
        box-shadow: none;
      }

      ::placeholder {
        color: #767676;
      }

      #forgotten {
        color: #5580db;
        width: 25em;
        cursor: pointer;
      }

      .select {
        margin-top: 1em;
        padding-top: 0.5em;

        padding-right: 1em;
        padding-left: 1em;
        width: 27em !important;
      }

      input {
        border-radius: 4px;
        background-color: #ededed !important;
        border: none;
        margin-bottom: 0.5em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1em;
        padding-left: 1em;
        width: 20em;
        max-width: 25em;
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1em;
        margin-left: 2em;
        margin-top: 1em;
        width: min-content;
        label {
          z-index: 99;
          margin-left: 0.25em;
          > span {
            color: red;
          }
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-small +1) {
    align-items: center !important;
    form {
      justify-content: center !important;
      align-items: center !important;
    }
  }
}

.mediumWidth {
  width: 20em;
}

.green {
  color: green;
}

.red {
  color: red;
}

.internError {
  position: absolute;
  right: 5px;
  top: 5px;
}

.italic {
  font-style: italic;
}

.userMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1em;
  .card {
    width: 20%;
    margin: 1em;
    min-width: 18em;
    min-height: 5em;
    background-color: #f2f4f6;
    > .area {
      height: 100%;
    }
  }
  .cardContent {
    display: flex;
    font-weight: bold;
    justify-content: space-around;
    align-items: center;

    margin-right: 1em;
    height: 100%;
  }
}

.personnalDataShow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1em;
  > .data-display {
    margin-left: 1em;
  }
}

.formActions {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.buttonLink {
  color: white;
  margin-top: 1em;
  font-weight: bold;
  text-decoration: none;
  padding: 6px 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  margin-right: 1em;
  border-radius: 4px;
  background-color: $primaryColor;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-transform: uppercase;
  font-weight: bold;
  flex: 1.5;
}

.paiement {
  .left-align {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1em;
    margin-left: 1.75em;
  }

  button {
    min-width: 80px;
    min-height: 40px;
    border-radius: 3px;
    font-weight: bold;
    margin-top: 2em;
    align-self: flex-end;
    min-width: 10em;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  .cardSaved {
    margin-top: 1em;
    margin-left: 1.75em;
  }

  .smallMarginLeft {
    margin-left: 1.75em;
  }

  .smallMarginRight {
    margin-right: 1.75em;
  }

  .spaceAround {
    justify-content: space-around;
  }
  .small {
    width: 2.5em;
  }

  .lightblue {
    background-color: #d8eeff;
  }

  .blue:hover {
    background-color: $primaryColor;
  }

  .blue {
    background: $primaryColor;
  }

  .white {
    background: white;
    color: $primaryColor;
    border: 1px solid $primaryColor;
  }

  blue:disabled {
    background: #ccc;
    color: #999;
    cursor: default;
    border: none;
    outline: 0;
    box-shadow: none;
  }

  ::placeholder {
    color: #767676;
  }
  hr {
    margin-top: 1.75em;
  }
}

.blueText {
  color: $primaryColor;
}

.largerText {
  font-size: larger;
}

.notification {
  .message {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.success {
    > div {
      background-color: green;
    }
  }
  &.error {
    > div {
      background-color: red;
    }
  }
  &.warning {
    > div {
      background-color: orange;
    }
  }
}

.progressBar {
  width: 100%;
  margin-top: 0.5em;
}

.left-align-flex {
  display: flex;
  flex-direction: column;
  margin: 1em;
}

@media screen and (min-width: $breakpoint-small-survey + 1) {
  .show-in-small-media {
    display: none;
  }
  .show-in-medium-media {
    display: inline;
  }
}

@media screen and (max-width: $breakpoint-small-survey) {
  .show-in-small-media {
    display: inline;
  }
  .show-in-medium-media {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-very-small-survey) {
  #tab-survey-content {
    padding: 1rem;
  }

  .h1-survey {
    margin: 0px 0px 1rem 0px;
    padding: 0px !important;
    text-align: left;
  }
}

@media screen and (min-width: $breakpoint-very-small-survey + 1) {
  #tab-survey-content {
    padding: 2rem;
  }

  .h1-survey {
    margin: 0px 0px 2rem 0px;
    padding: 0px !important;
    text-align: left;
  }
}




.react-tel-input {
  border-bottom: 1px solid rgba(148, 148, 148, 1);
  &:hover {
    border-bottom: 2px solid #000;
  }


  &.focused {
    border-bottom: 2px solid #3f51b5 !important;
  }

  & + div:before {
    position: absolute;
    top: -7px;
    left: 19px;
    display: block;
    background: white;
    padding: 0 5px;
    font-size: 13px;
    width: 70px;
  }
  .form-control {
    color: currentColor;
    font-size: 16px;
    background: #b9b9b947;
    border: 1px solid #b9b9b947;
    border-radius: 4px;
    width: 13.5em;
    margin-left: 4em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1em;
    padding-left: 1em;
    outline: none;
    transition: box-shadow ease 0.25s, border-color ease 0.25s;
    &:hover {
      border: none;
    }
    &:focus {
      border: none;
      box-shadow: none;
      background: #b9b9b947;

    }
  }
  .flag-dropdown {
    outline: none;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    border-radius: 3px 0 0 3px;
    &.open {
      z-index: 2;
    }
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
  input[disabled] {
    & + .flag-dropdown {
      &:hover {
        cursor: default;
        .selected-flag {
          background-color: transparent;
        }
      }
    }
  }
  .selected-flag {
    position: relative;
    width: 52px;
    height: 100%;
    padding: 0 0 0 11px;
    border-radius: 3px 0 0 3px;
    .flag {
      position: absolute;
      top: 50%;
      margin-top: -12px;
    }
    .arrow {
      position: relative;
      top: 50%;
      margin-top: -1px;
      left: 29px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;
      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }
  .country-list {
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
    background-color: white;
    width: 300px;
    max-height: 220px;
    overflow-y: scroll;
    border-radius: 7px;
    .flag {
      display: inline-block;
      position: absolute;
      left: 13px;
      top: 8px;
    }
    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }
    .country {
      position: relative;
      padding: 12px 9px 13px 46px;
      .dial-code {
        color: #6b6b6b;
      }
      &:hover {
        background-color: #f1f1f1;
      }
      &.highlight {
        background-color: #f1f1f1;
      }
    }
    .flag {
      margin-right: 7px;
      margin-top: 2px;
    }
    .country-name {
      margin-right: 6px;
    }
    .search {
      z-index: 2;
      position: sticky;
      top: 0;
      background-color: #fff;
      padding: 10px 0 6px 10px;
    }
    .search-emoji {
      display: none;
      font-size: 15px;
    }
    .search-box {
      border: 1px solid #cacaca;
      border-radius: 3px;
      font-size: 15px;
      line-height: 15px;
      margin-left: 6px;
      padding: 3px 8px 5px;
      outline: none;
      &:hover {
        border-color: #505050;
      }
    }
    .no-entries-message {
      padding: 7px 10px 11px;
      opacity: 0.7;
    }
  }
}


.warningIcon {
  color: rgba(232, 138, 61,1);
  position: relative;
  width: 44px;
  height: 44px;
  border: none;
  box-shadow: 0 0 0 0 rgba(232, 138, 61, 0.7);
  border-radius: 50%;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 138, 61, 0.7);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}


.alertIcon {
  color: rgb(255, 64, 58);
  position: relative;
  width: 44px;
  height: 44px;
  border: none;
  box-shadow: 0 0 0 0 rgba(255, 64, 58, 0.7);
  border-radius: 50%;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 64, 58, 0.7);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}

.transactionPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 64px;
  padding: 20px;

  .validTransactionMessage {
    align-self: center;
    font-size: 1.4em;
    margin: 10%;
  }

  .actionButtons {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    width: 80%;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
  }
  
  .summary {
    margin-left: 10%;
    margin-right: 10%;

    .summaryInfo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 80%;
      font-weight: bold;
      color: #df9903;
      margin-bottom: 40px;
    }

    .listOfPlans {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .accessToXP {
      margin-top: 40px;
      font-weight: bold;
    }

    .walletAmount, .activationCode, .accessToXP {
      display: flex;
    }
  }

}

.telephonyProcessItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1em;
  margin-top: 5px;
  margin-bottom: 10px;
}

.telephonyProcessItemValue {
  margin-left: 1em;
  font-weight: bold;
}