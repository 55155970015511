@import "../../../styles/variables.scss";
$important: unquote("!important");

.InfoMsg{
    font-size: smaller;
    width: 20em;
}

.ErrorMsg {
    font-size: smaller;
    color: red;
    width: 20em;
}

.FormDiv {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

.PersonalForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: start !important;
}

.Form {
    display: flex;
    flex-direction: column;
}


.FormButton {
    width: 8em;
}

.ErrorMsgStart{
    font-size: smaller;
    color: red;
    align-self: flex-start;
}

.LoadingSpinner {
    color: $primaryColor $important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}


.Wrapper {
    position: relative;
    margin: 0.25em;
}