@import "../../../styles/variables.scss";
$important: unquote("!important");

.ActivationCodeReq {
    padding: 1em 0 0 0;
    align-self: flex-start;
}

.ActivationCodeDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ActivationButton {
    color: $primaryColor $important;
    font-size: x-small !important;
    border: 1px solid rgba(0, 0, 0, 0.13)!important;
    width: 45%;
}

.Selected {
    color: white;
    background-color: $primaryColor $important;
    font-size: x-small !important;
    border: 1px solid rgba(0, 0, 0, 0.13)!important;
    width: 45%;
}

.BackButton {
    margin-right: 1em !important;
    color: $primaryColor $important;
    border: 1px solid $primaryColor;
}

.CGU {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;
}

.CGUText {
    text-align: start;
    align-self: center;
    font-size: smaller;
}

.InfoMsg{
    font-size: smaller;
    text-align: left;
    align-self: flex-start;
}

.ErrorMsg{
    font-size: smaller;
    text-align: left;
    color: red;
    align-self: flex-start;
}

.Link {
    cursor: pointer;
    color: $primaryColor;
}

.ActivationCodeMsgConteneur {
    display: flex;
    align-items: center;
    margin: 0.5em;
    width: 100%;
    justify-content: center;
}

.ActivationCodeMsg {
    text-align: start;
    margin-left: 0.5em;
}

.PhoneNumber {
    overflow: hidden;
    padding-bottom: 0.25em;
    border-bottom: 1px solid black;
}

.PhoneLabel{
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: smaller;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-left: 0.5em;
    align-self: flex-start;
    margin-bottom: 0.5em;
}