.LoadingSpinner {
    color: #2196F3 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}


.Wrapper {
    position: relative;
    margin: 0.25em;
}