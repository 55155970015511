.CardDiv{
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  border: 1px solid #ded8d5;
  padding: 1em;
  border-radius: 5px;
  width: fit-content;
}

.Title {
  font-weight: bold;
}

.Card {
  margin: 0.25em;
}

.PaymentForm {
  display: flex;
  flex-direction: column;
}

.CardInput {
  margin: 0.5em !important;
}

.PaymentLine{
  display: flex;
  margin: 0.5em;
  flex-wrap: wrap;
}


.PaymentOneLine{
  display: flex;
  flex-direction: column;
  margin: 1em;
}


.InfoMsg{
  font-size: xx-small;
}

.ErrorMsg{
  font-size: smaller;
  color: red;
}

.WithInfo {
  margin: 0.5em !important;
  display: flex;
  flex-direction: column;
}

.PaymentInfo{
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
}

.Text {
  padding: 1.25em;
}



.ErrorMsgContent {
  font-size: smaller;
  color: red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}