@import "../../../styles/variables.scss";
$important: unquote("!important");

$breakpoint-small: 601px;
$blue: $primaryColor;

.FileDummyContent {
  min-width: 0;
  display: flex;
  align-items: center;
}

.FileDummyContentMini {
   min-width: 0;
   display: flex;
   flex-direction: column;
}

.DocumentButton {
  margin: 0.25em !important;
  background-color: $primaryColor $important;
  color: white !important;
}

.WhiteDocumentButton {
  margin: 0 !important;
  border: 1px solid $primaryColor $important;
  background-color: white !important;
  color: $primaryColor $important;
}

.FileTypeName {
  color: $primaryColor;
  font-weight: bold;
}

.Image {
  max-width: 100%;
}

.Hidden {
  display: none;
}

.PopupTitle {
  font-weight:bold;
}


.SimpleCard {
    width: 25em;
    height: 14em;
    padding: 1.5em;
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content: space-between;
}

.TextCard {
  margin-top: 8%;
  transform: translateY(-50%);
}

.Actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ImagePreview {
  max-width: 20em;
  width:auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.LoadingSpinner {
  color: $primaryColor $important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}


.Wrapper {
  position: relative;
}