@import "../../../styles/variables.scss";
$important: unquote("!important");

.container {
    display: flex;
    padding: 1em;
    flex-direction: column;
    align-items: center;
}

.title {
    padding-top: 2em;
}

.subtitle {
    font-weight: bold;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button {
    margin-left: 10px;
    margin-right: 10px;
}

.checkRoomTitle {
    color: $primaryColor;
}

.tvCodeMsgContainer {
    display: flex;
    align-items: center;
    margin: 1em 0 1em 0;
    max-width: 30em;
    justify-content: center;
}

.roomSelector {
    width: 50%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
  }