.HomeImg {
    max-width: 8em;
    padding: 0.5em;
}

.HomeHeader {
    display: flex;
  width: 90%;
  justify-content: space-evenly;
  padding: 0.5em;
  align-items: center;
}

.HomeTitle {
    font-weight: bold;
    font-style: italic;
    font-size: smaller;
}

.HomeCard {
 width: 20%;
 margin: 1em;
 min-width: 18em;
 min-height: 5em;
 background-color: #f2f4f6;

}

.HomeCard2 {
    margin: 1em;
    min-width: 18em;
    min-height: 5em;
    background-color: #f2f4f6 !important;
    display: flex;
    align-items: center;
}


.HomeArea {
    width: 100%;
    display: block;
    height: 100%;
    text-align: inherit;
}

.HomeCardContent {
    display: flex;
    font-weight: bold;
    justify-content: space-around;
    align-items: center;
    margin-right: 1em;
    height: 100%;
    padding: 16px;
}

.HomeIcon {
    flex: 1;
}

.HomeCardTitle {
    flex: 3;
    font-weight: bold;
}

.HomePage {
    margin-top: 4em;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4em;
}