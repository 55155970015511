.Actions {
    display: flex;
    align-self: flex-end;
    justify-content: space-between;
    width: 100%;
    color: rgba(241, 241, 250, 0.315);
}

.InfoMsg {
    font-size: smaller;
}

.CellContent {
    display: flex;
    align-items: center;
}