.Actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

.BackButton {
    align-self: flex-start;
}

.Button {
    align-self: center;
    margin-top: 2em !important;
}

.PageContent {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    justify-content: center;
}