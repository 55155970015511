@import "../../../styles//variables.scss";

$important: unquote("!important");
$breakpoint-small: 601px;

.FileDummyDiv {
  max-width: 100%;
  width: 100%;
}

.SimpleCard {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.DocumentButton {
  margin-left: 0.25em !important;
  margin-right: 0.25em !important;
  background-color: $primaryColor $important;
  color: white !important;

}

.DocumentButton:disabled {

    color: rgb(170, 170, 170) !important;
    background-color: rgb(200, 200, 200) !important;
  
}

.WhiteDocumentButton {
  margin-left: 0.25em !important;
  margin-right: 0.25em !important;
  border: 1px solid $primaryColor $important;
  background-color: white !important;
  color: $primaryColor $important;
}

.mediumCardSize {
  width: 25em;
  height: 14em;
  padding: 1.5em;
}

.Upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 2em;
}

.Content {
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  border: 2px solid $primaryColor;
  border-radius: 5px;
}

.Files {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 2;
  overflow-y: auto;
}

.Label {
  flex: 1;
}

.Actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 32px;
}

.Header {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.Title {
  color: #555;
}

.FileTypeName {
  color: $primaryColor;
  font-weight: bold;
}

.DocumentCard {
  margin: 1em;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.selfCenter {
  align-self: center;
}

.Page {
  margin-top: 1em;
}

.InfoMsg {
  font-size: smaller;
}

.ErrorMsg {
  font-size: smaller;
  color: red;
}

.ProgressBar {
  width: 100%;
  margin-top: 0.5em;
}



.LoadingSpinner {
  color: $primaryColor $important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}


.Wrapper {
  position: relative;
  margin: 0.25em;
}