.Actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 2em;
  align-items: center;
  width: 100%;
  align-self: center;
}

.Fixed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
  width: 100%;
  align-self: center;
  bottom: 0;
  background-color: white;
  position: fixed;
}