.ErrorMsg {
  color: rgb(244, 67, 54);
  font-size: 0.7rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.InfoMsg {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.7rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.FieldLabel {
  color: #1f1f1f;
  padding: 0;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  margin-left: 0.8em;
  margin-bottom: 0.25em;
}

.FieldLabelError {
  color: rgb(244, 67, 54);
  padding: 0;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  margin-left: 0.8em;
  margin-bottom: 0.25em;
}

.EmptyLoader {
  display: flex;
  flex-direction: column;
  width: 15em;
  align-items: flex-start;
  justify-items: flex-start;
  margin-right: 0.5em;
  overflow-y: auto;
}

.Files {
  align-items: flex-start;
  justify-items: flex-start;
  margin-right: 0.5em;
  width: 15em;
  overflow-y: auto;
  opacity: 0.6;
}

.Row {
  display: flex;
  flex-direction: row;
}

.Content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.5em;
  margin-top: 0.5em;
}

.FileDiv {
  display: flex;
  flex-direction: row;
  height: 3em;
  border: 1px solid #0000004f;
  border-radius: 5px;
  position: relative;
  justify-content: space-between;
  background-color: #b9b9b947;
  align-items: center;
}

.FileDivError {
  display: flex;
  flex-direction: row;
  height: 3em;
  background-color: rgba(255, 0, 0, 0.1) !important;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgb(244, 67, 54);
  border-radius: 5px;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.FileInput {
  width: 14rem;
  vertical-align: middle;
  height: 100%;
  padding-left: 0.5em;
  min-height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
  }
}


.DisabledFileInput {
  width: 14rem;
  vertical-align: middle;
  height: 100%;
  padding-left: 0.5em;
  min-height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  opacity: 0.5;
  > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
  }
}


.centered {
  display: flex;
  margin-right: 2%;
  align-items: center;
}

.sized09rem {
  font-size: 0.9rem;
}

.sized1rem {
  font-size: 1rem;
}

.ImagePreview {
  max-width: 20em;
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
