.MiniModal {
    display: flex;
    padding-left: 1em;
    padding-bottom: 1em;
    flex-direction: column;
}

.Header{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.CloseButton {
    align-self: flex-start;
}

.Content {
    padding-right: 1em;
}