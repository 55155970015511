@import '../../../styles/variables.scss';

.FileTypeName {
  color: $primaryColor;
  font-weight: bold;
    text-align: left !important;
}
  
.FileDescText {
  text-align: left;
}

.selfCenter {
  align-self: center;
}

.Page{
  margin-top: 1em;
}



$breakpoint-small: 601px;

.DocumentList2 {
    padding: 0.25em;
}

.ListItemCard{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.DocumentContent{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
  margin-left: 0.5em;
}

.DocumentCard {
  margin: 1em;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex1 {
  flex: 1;
}

.uploaderTitle {
  margin-bottom: 1em;
}

.documentButton {
  margin-left: 0.25em !important;
  margin-right: 0.25em !important;
}

.button:hover {
  cursor: pointer;
}

.errorMsg {
  color: red;
  font-size: smaller;
}

.infoMsg {
  font-size: smaller;
}

.pluginPage {
  display: flex;
  flex-direction: column;
}


.MinMaxContentWidth {
    min-width: 0;
}


.notifyAnimated {
  transition-property: background-color;
  transition-delay: 1s;
}

.pulseButton {
  position: relative;
  width: 44px;
  height: 44px;
  border: none;
  box-shadow: 0 0 0 0 rgba(232, 138, 61, 0.7);
  border-radius: 50%;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 138, 61, 0.7);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}
