.ErrorMsg {
    font-size: smaller !important;
    color: red !important;
    align-self: flex-start !important;
    text-align: left;
    margin-bottom: 0.25em;
}

.FormInput {
    margin-top: 1em !important;
    width:100%;
    label {
      z-index: 99 !important;
      margin-left: 0.5em !important;
      > span {
        color: red !important;
      }
    }
    > div {
      width: 100%;
    }
}

.Helper {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
}

.Icon {
    margin: 0.5em;
}