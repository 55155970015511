.RefDate {
  color: #2996f3;
  font-weight: bold;
}

.Subtitle {
  font-size: smaller;
}

.ErrorMsg {
  color: red;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.WarningMsg {
  color: #e46c31;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.Empty {
  align-self: center;
    padding: 2em;
    font-weight: 700;
}

.Content {
  width: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-self: center;
}

.SelectedSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.Form {
  display: flex;
  flex-direction: column;
  padding: 1em;
  align-self: center;
  width: 100%;
}