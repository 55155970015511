.Logo {
    max-width: 20em;
    margin-bottom: 1em;
}

.ErrorMsg {
    font-size: smaller;
    color: red;
    display: flex;
    align-items: center;
}

.Icon {
    margin-right: 0.25em;
}

.LoginForm {
    align-self: center;
    width: 100%;
}

.Form {
    min-width: 20em;
}