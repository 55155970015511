.ErrorMsgIcon {
  margin-top: 0.5em;
  font-size: smaller;
  color: red;
  display: flex;
  align-items: center;
}

.ErrorMsg {
  align-self: start;
  font-size: smaller;
  text-align: left;
  color: red;
}

.InfoMsg {
  text-align: left;
  font-size: smaller;
}

.Icon {
  margin-right: 0.25em;
}