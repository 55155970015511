$body-color: #000000;
$background-color: rgb(242, 244, 246);
$primaryColor: #2196F3;
$secondaryColor: #1565c0;
$thirtyColor: #90caf9;
$footer-color: #CFECEF;
$header-color-light: #FFFFFF;
$button-color: #000000;
$primary-button-background-color: #FF6321;
$secondary-button-background-color: #BCCD0B;
$tertiary-button-color: #FF6321;
$tertiary-button-background-color: #FFFFFF;
$tertiary-button-border-color: #FF6321;
$tertiary-button-hover-color: #FF6321;
$disabled-button-color: #696969;
$disabled-button-background-color: #E4E4E4;
$link-button-border-color: #FF6321;
$back-button-color: #FFFFFF;
$back-button-background-color: #008C99;
$next-button-background-color: #FFFFFF;
$next-button-focused-background-color: #E4E4E4;

$other-button-ripple-color: #008C99;

$input-color: #000000;
$input-border-color: #000000;
$input-active-border-color: #008C99;
$input-background-color: #FFFFFF;
$error-color: #B00020;

$checkbox-inactive-color: #000000;
$checkbox-active-color: #008C99;

$title-color: #1F5669;
$separator-color: #696969;
$separator-alternative-color: #BCCD0B;
$heading-color: #008C99;

$icon-admission-color: #FF6321;
$icon-carePath-color: #3BCA9D;
$icon-stayInformations-color: #BCCD0B;
$icon-dmp-color: #9D91F2;
$icon-payment-color: #00A8B8;
$icon-appointment-color: #FF5CAF;
$icon-survey-color: #FF6321;

$font-service-title: 600 20px "Metropolis", sans-serif;

$header-3-size: 18px;
$title-S-size: 28px;
$header-1-S-size: 22px;
$header-2-S-size: 18px;
$header-3-S-size: $header-3-size;
$title-M-size: 40px;
$header-1-M-size: 28px;
$header-2-M-size: 20px;
$header-3-M-size: $header-3-size;
$title-L-size: 60px;
$header-1-L-size: 36px;
$header-2-L-size: 25px;
$header-3-L-size: $header-3-size;

$breakpoint-small: 601px;

$breakpoint-small-survey: 700px;
$breakpoint-very-small-survey: 400px;